import { bookList } from '../../constants/bookList';

import SvgIcon from '../ui-core/svg-icon';
import { SVG_CODES } from '../ui-core/svg-icon/svg-icon.constants';


export const primaryData = [
    {
        title: 'Dashboard',
        key: 'dashboard',
        href: bookList.dashboard.entry_point,
        icon: <SvgIcon type={SVG_CODES.SIDEBAR_DASHBOARD} />,
        isBadge: false,
        cName: 'nav__icon-dashboard',
    },
    {
        title: 'Live Operations',
        key: 'live-operations',
        href: bookList.live_operations.default,
        icon: <SvgIcon type={SVG_CODES.SIDEBAR_LOCATION} />,
        isBadge: false,
        cName: 'nav__icon-live-operations',
    },
    {
        title: 'Routes',
        key: 'routes',
        href: '/routes/tsp-statistics',
        icon: <SvgIcon type={SVG_CODES.SIDEBAR_ROUTES} />,
        isBadge: false,
        cName: 'nav__icon-routes',
    },
    // {
    //     title: "Emergency",
    //     key: "emergency",
    //     href: "/emergency",
    //     icon: <IconSVG type="emergency" />,
    //     isBadge: false,
    //     cName: "nav__icon-emergency",
    // },
    {
        title: 'Intersections',
        key: 'intersections',
        href: bookList.intersections.spm,
        icon: <SvgIcon type={SVG_CODES.SIDEBAR_INTERSECTIONS} />,
        isBadge: false,
        cName: 'nav__icon-intersections',
    },
    {
        title: 'Alerts',
        key: 'alerts',
        href: bookList.alerts.active,
        icon: <SvgIcon type={SVG_CODES.SIDEBAR_ALERTS} />,
        isBadge: false,
        cName: 'nav__icon-alerts',
    },
];

export const productionData = primaryData.filter(item => item.key !== 'intersections' && item.key !== 'live-operations');

export const secondaryData = [
    // {
    //     title: 'Settings',
    //     key: 'settings',
    //     href: bookList.settings.profile,
    //     icon: <SvgIcon type={SVG_CODES.SIDEBAR_SETTINGS} />,
    //     cName: 'nav__icon-settings',
    // },
    {
        title: 'Help',
        key: 'help',
        href: '/help', // bookList.help.faq,
        icon: <SvgIcon type={SVG_CODES.SIDEBAR_HELP} />,
        cName: 'nav__icon-help',
    },
];

export const productionSecondaryData = secondaryData.filter(item => item.key !== 'help');

export const adminData = [
    {
        title: 'Business Rules',
        key: 'business-rules',
        href: bookList.business_rules.routes,
        icon: <SvgIcon type={SVG_CODES.SIDEBAR_DOCUMENTS} />,
        isBadge: false,
        cName: 'nav__icon-business-rules',
    },
    {
        title: 'Users & Permissions',
        key: 'users-and-permissions',
        href: bookList.users_and_permissions.users,
        icon: <SvgIcon type={SVG_CODES.SIDEBAR_USER_GROUPS} />,
        isBadge: false,
        cName: 'nav__icon-user-and-permissions',
    },
];

export const productionAdminData = adminData.filter(item => item.key !== 'business-rules');
