/* eslint-disable keyword-spacing */
import { Provider } from 'react-redux';
import { persistor, store } from './store/store';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider as RollbarProvider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react'; // <-- Provider imports 'rollbar' for us

import ErrorBoundary from './components/error-boundary';
import Main from './components/app';

import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/dist/rsuite.min.css';
import './assets/styles';

const rollbarConfig = {
    enabled: true,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.npm_config_preact_app_environment,
    accessToken: process.env.npm_config_preact_app_rollbar_access_token,
};

const isRollbarTest = process.env.npm_config_preact_app_rollbar_environment === 'testenv';

const TestError = () => {
    const a = null;
    return a.hello();
};

const root = document.querySelector('[class^="app__wrapper"]');

if (root && root.hasChildNodes()) {
    root.innerHTML = ''; // Clears the existing content
}


const App = () =>
    <RollbarProvider config={rollbarConfig}>
        <RollbarErrorBoundary>
            <ErrorBoundary>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        {!isRollbarTest && <Main />}
                        {isRollbarTest && <TestError />}
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        </RollbarErrorBoundary>
    </RollbarProvider>
;

export default App;
