/* eslint-disable keyword-spacing */
/* eslint-disable space-before-function-paren */
import { get } from '../../api';
import { getUserCityId } from '../request.utils';

export const getIntersectionsListRequest = async () => {
    const cityId = getUserCityId();

    const url = `intersections?city_id=${cityId}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getIntersectionByIdRequest = async (id) => {
    const url = `intersections/${id}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getIntersectionHistoryRequest = async (params = {}) => {
    const url = `intersections/history/live?${new URLSearchParams(params).toString()}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getIntersectonsOperationalDetail = async () => {
    const url = 'intersections/operational';

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
